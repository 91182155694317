import Index, { Props } from '../components/pages/Index';

import { loadNewsFromMicroCmsForLP } from '../modules/news';
import { loadColumnsFromMicroCmsForLP } from '../modules/column';
import { getSuperUpstreamExcitingProjects } from '../modules/project';
import { GetStaticProps } from 'next';

export const getStaticProps: GetStaticProps<Props> = async () => {
    // microCMS:news取得
    const newsContent = await loadNewsFromMicroCmsForLP();
    // microCMS:column取得
    const columnContent = await loadColumnsFromMicroCmsForLP();
    // 超上流の刺激的な案件取得
    const superUpstreamExcitingProjects =
        await getSuperUpstreamExcitingProjects();
    return {
        props: {
            newsContentList: newsContent,
            columnContentList: columnContent,
            superUpstreamExcitingProjects
        },
        revalidate: 180
    };
};

export default Index;
