import Axios, { AxiosRequestConfig as RequestConfig } from 'axios';

export type AxiosRequestConfig = RequestConfig<undefined>;

const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL as string;

const axiosInstance = Axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        Accept: '*/*'
    }
});
axiosInstance.interceptors.response.use(function (response) {
    return response.data;
});
export default axiosInstance;
