import {
    formatDate,
    isPast,
    parseDate,
    getNextMonthFirstAndLastDate,
    getCurrentMonthFirstAndLastDate
} from '@/modules/date';
import api, {
    getSuperUpstreamExcitingProjectPrefixPath,
    searchProjectPrefixPath
} from '@/modules/api/axios';
import {
    AT_OFFICE_FREQUENCY,
    othersAtOfficeFrequency
} from '@/constants/master';
import { formatMasterToString } from '@/modules/tool';
import { Master, MasterIconFile } from '@/types/master';
import {
    atOfficeFrequencyIdsWithSuffix,
    officeFrequencySuffix,
    otherIconFile,
    projectIconList
} from '@/modules/config/project';
import { ProjectProps } from '@/types';
import { ProjectsFilterFormParams } from '@/types/project';
import { STARTTIME_MASTERS_IDS } from '@/modules/config/master';

export type SearchResponse<T> = {
    hitsCount: number;
    pageCount: number;
    hitsPerPage: number;
    currentPage: number;
    results: T[];
};

export type SearchProjectSortType = 'new' | 'monthlyRate';

export type SearchProjectRequestProps = {
    orderBy: SearchProjectSortType;
    page: number;
    text: string;
    atOfficeFrequency: string | null;
    area: string | null;
    consultingGenre: string | null;
    industryTags: string[];
    lowestOperationRate: string | null;
    highestOperationRate: string | null;
    lowerLimitMonthlyRate: number | null;
    upperLimitMonthlyRate: number | null;
    isRecruitingOnly: boolean;
    earlierProjectStartDate?: string;
    laterProjectStartDate?: string;
};

type SearchProjectResponseProps = {
    id: string;
    name: string;
    constructorTierLevel: Master | null;
    isPlatform: boolean;
    consultingGenre: Master | null;
    highestReward: number | null;
    lowestReward: number | null;
    startDate: string;
    endDate: string;
    isExtendable: boolean;
    highestOperationRate: Master | null;
    lowestOperationRate: Master | null;
    hiringStatus: Master | null;
    area: Master | null;
    numberOfApplicants: string;
    atOfficeFrequency: { master: Master | null; others: string };
    industryTags: Master[];
    hiringSummary: string;
    requiredSkills: string;
    skillTags: Master[];
};

export const initialProjectSearchRequest: SearchProjectRequestProps = {
    orderBy: 'new',
    page: 1,
    text: '',
    atOfficeFrequency: null,
    area: null,
    consultingGenre: null,
    industryTags: [],
    lowestOperationRate: null,
    highestOperationRate: null,
    lowerLimitMonthlyRate: null,
    upperLimitMonthlyRate: null,
    isRecruitingOnly: true
};

export const searchProjectByText = async (searchText?: string) => {
    const searchProjectsRes = await searchProjects({
        ...initialProjectSearchRequest,
        text: searchText || ''
    });
    return searchProjectsRes;
};

export const searchProjects = async (
    req: SearchProjectRequestProps
): Promise<SearchResponse<ProjectProps>> => {
    const res = await api.post<
        SearchProjectRequestProps,
        SearchResponse<SearchProjectResponseProps>
    >(searchProjectPrefixPath, req);
    const convertedProjects = convertProjectsToJson(res.results);
    return {
        ...res,
        results: convertedProjects
    };
};

export const getSuperUpstreamExcitingProjects = async (): Promise<
    ProjectProps[]
> => {
    const res = await api.get<SearchProjectResponseProps[]>(
        getSuperUpstreamExcitingProjectPrefixPath
    );
    return convertProjectsToJson(res);
};

const convertProjectsToJson = (
    projects: SearchProjectResponseProps[]
): ProjectProps[] =>
    projects.map((prj) => {
        return {
            id: prj.id,
            title: prj.name,
            maxFee: prj.highestReward ? prj.highestReward.toString() : '',
            minFee: prj.lowestReward ? prj.lowestReward.toString() : '',
            taskStartDate: formatDate(prj.startDate),
            taskEndDate: formatDate(prj.endDate),
            consultingGenre: prj.consultingGenre,
            operationMin: formatMasterToString(prj.lowestOperationRate),
            operationMax: formatMasterToString(prj.highestOperationRate),
            mainWorkArea:
                AT_OFFICE_FREQUENCY.REMOTE_WORK !==
                prj.atOfficeFrequency?.master?.id
                    ? formatMasterToString(prj.area)
                    : formatMasterToString(prj.atOfficeFrequency?.master),
            requiredSkills: prj.requiredSkills,
            constructorTierLevel: prj.constructorTierLevel,
            isPlatform: prj.isPlatform,
            industryTags: prj.industryTags,
            atOfficeFrequency: prj.atOfficeFrequency.master
                ? prj.atOfficeFrequency
                : null,
            numberOfApplicants: prj.numberOfApplicants,
            hiringSummary: prj.hiringSummary,
            skillTags: prj.skillTags
        };
    });

export const formatProjectDate = (
    prjStartDate: String,
    prjEndDate: String,
    emptyMessage = 'ー',
    separator = ' ~ ',
    postText = ''
) => {
    if (!prjStartDate && !prjEndDate) return emptyMessage;

    const parseStartDate = parseDate(prjStartDate.toString());
    if (isPast(parseStartDate)) {
        return `即日${separator}${prjEndDate}${postText}`;
    }

    return `${prjStartDate}${separator}${prjEndDate}${postText}`;
};

export const convertConsultingGenreToProjectIcon = (
    masterId: number | undefined
): MasterIconFile => {
    const projectIcon = projectIconList.find(
        (icon) => icon.masterId === masterId
    );
    if (!projectIcon) return otherIconFile;
    return projectIcon;
};

// 末尾に`出社`をつけて表示する
export const generateOfficeFrequencyText = (
    officeFrequency: Master | null | undefined
): string => {
    if (!officeFrequency) return '';

    if (atOfficeFrequencyIdsWithSuffix.includes(parseInt(officeFrequency.id))) {
        return officeFrequency.text + officeFrequencySuffix;
    }
    if (officeFrequency.id === othersAtOfficeFrequency.id) {
        return '';
    }
    return officeFrequency.text;
};

export const convertSearchProjectQuery = (
    params: ProjectsFilterFormParams
): SearchProjectRequestProps => {
    const { earlierProjectStartDate, laterProjectStartDate } =
        generateStartDateQuery(params?.startTime || '');

    const industryTags = params?.industryTags
        ? params?.industryTags?.split(',')
        : [];

    return {
        orderBy: params?.orderBy || 'new',
        page: params?.page ? parseInt(params.page) : 1,
        text: params?.text || '',
        atOfficeFrequency: params?.atOfficeFrequency || null,
        area: params?.area || null,
        consultingGenre: params?.consultingGenre || null,
        industryTags,
        lowestOperationRate: params?.lowestPercentageOfOperations || null,
        highestOperationRate: params?.highestPercentageOfOperations || null,
        lowerLimitMonthlyRate: params?.lowerLimitMonthlyRate
            ? parseInt(params.lowerLimitMonthlyRate)
            : null,
        upperLimitMonthlyRate: params?.upperLimitMonthlyRate
            ? parseInt(params.upperLimitMonthlyRate)
            : null,
        earlierProjectStartDate,
        laterProjectStartDate,
        isRecruitingOnly: true
    };
};

const generateStartDateQuery = (startTime: string) => {
    if (startTime === STARTTIME_MASTERS_IDS.CURRENT_MONTH) {
        const dates = getCurrentMonthFirstAndLastDate();
        return {
            earlierProjectStartDate: dates.currentMonthFirstDate,
            laterProjectStartDate: dates.currentMonthEndDate
        };
    }

    if (startTime === STARTTIME_MASTERS_IDS.NEXT_MONTH) {
        const dates = getNextMonthFirstAndLastDate();
        return {
            earlierProjectStartDate: dates.nextMonthFirstDate,
            laterProjectStartDate: dates.nextMonthEndDate
        };
    }

    return {
        earlierProjectStartDate: undefined,
        laterProjectStartDate: undefined
    };
};

export type SearchProjectFeature = 'GENERAL' | 'PMO' | 'SAP';

export const isGeneralSearchPage = (
    feature: SearchProjectFeature
): feature is 'GENERAL' => feature === 'GENERAL';

export const fixFeatureText = (
    params: SearchProjectRequestProps,
    feature: SearchProjectFeature
) => {
    if (!isGeneralSearchPage(feature)) {
        const text = params.text;
        if (text !== undefined && !text.includes(feature)) {
            params.text = [text, feature].join(' ').trim();
        }
    }
    return params;
};
