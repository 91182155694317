import Link from 'next/link';
import React from 'react';
import { useUrlContext } from '../../../provider/urlContext';

type Props = {
    label: string;
};

const CategoryAsTagChip: React.FC<Props> = ({ label }) => {
    const { query } = useUrlContext();
    return (
        <Link
            href={`/columns/category/${label}/1${query}`}
            passHref
            className="inline-block px-[8px] py-[2px] text-[12px] font-[700] text-[#314357] bg-[#dae2ea] rounded-[2px] mr-[8px] mb-[8px]"
        >
            {label}
        </Link>
    );
};

export default CategoryAsTagChip;
