import React from 'react';
import QandA from './QandA';
import styles from './styles.module.css';
import { contents } from './supportData';

const Support: React.FC = () => {
    const QAList = contents.map((content, id) => (
        <QandA key={id} question={content.question} answer={content.answer} />
    ));

    return (
        <section
            id="q&a"
            className="lg:mx-auto mt-6 py-10 w-full lg:w-[1000px] md:py-[80px] md:mt-10"
        >
            <div className="bg-white rounded md:mx-4 lg:mx-0 py-20">
                <div className="flex items-center">
                    <h2 className="text-[32px] leading-[46px] ml-4 mr-[8px] md:text-[46px] md:ml-[40px] md:mr-[12px]">
                        /
                    </h2>
                    <h2
                        className="text-[32px] mr-[12px] leading-[46px] font-medium md:text-[46px] md:mr-[24px]"
                        id={styles.titleNumber}
                    >
                        02
                    </h2>
                    <h2
                        className="w-[241px] md:w-[192px] font-bold md:text-[32px] leading-[46px]"
                        id={styles.titleText}
                    >
                        高品質な対応
                    </h2>
                </div>
                <p className="text-[14px] leading-[19px] mx-auto w-[311px] my-[24px] font-medium whitespace-pre-wrap md:text-[18px] lg:w-[575px] md:leading-[25px] md:ml-[140px] md:mt-[20px] md:mb-[40px] md:whitespace-nowrap">
                    連絡速い、信頼できる、フリーランス周辺事情も相談できるので心強い
                </p>
                <div className="flex flex-col justify-center items-center">
                    {QAList}
                </div>
            </div>
        </section>
    );
};

export default Support;
