import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const media = [
    '/images/media/h-rog.png',
    '/images/media/at-jinji.png',
    '/images/media/min-career.png',
    '/images/media/value-works.png',
    '/images/media/nikkei.png',
    '/images/media/diamond-online.png',
    '/images/media/it-media.png',
    '/images/media/money-jidai.png',
    '/images/media/asahi.png',
    '/images/media/columnbus.png',
    '/images/media/e-columnbus.png',
    '/images/media/jinji-management.png'
];

type Props = {
    text: string;
};

const Media: React.FC<Props> = ({ text }) => {
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => setMounted(true), []);

    return (
        <>
            {mounted && (
                <section className="w-full py-[32px] lg:py-[57px] bg-[#fff]">
                    <p className="text-center text-[15px] lg:text-[26px] lg:leading-6 font-[600] pb-[20px]">
                        {text}
                    </p>
                    <Swiper
                        loop={true}
                        slidesPerView={2}
                        modules={[Autoplay]}
                        allowTouchMove={false}
                        autoplay={{ delay: 0 }}
                        speed={5000}
                        breakpoints={{
                            750: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 5
                            }
                        }}
                        className="h-[26px] lg:h-16"
                    >
                        {media.map((item, index) => (
                            <SwiperSlide
                                className="w-full flex items-center"
                                key={index}
                            >
                                <div className="w-full flex h-[26px] lg:h-[40px] items-center justify-center px-[16px]">
                                    <img
                                        className="h-full object-contain"
                                        src={item}
                                        alt="media"
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </section>
            )}
        </>
    );
};

export default Media;
