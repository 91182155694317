import styles from './styles.module.css';
import Case from '../Achievement';
import { caseData } from './caseData';
import { useEffect, useState } from 'react';

const Appeal2 = () => {
    // hydrationエラー防止のため
    const [isClient, setIsClient] = useState(false);
    useEffect(() => setIsClient(true), []);

    return (
        <section className="bg-primary" id="achievement">
            <div className="md:mx-4 lg:w-[1000px] h-auto lg:mx-auto rounded bg-white md:py-20 pt-10 pb-3">
                <div className="flex items-center md:pl-10 pl-4">
                    <h2 className={`${styles.slash} md:mr-3 mr-2`}>/</h2>
                    <h2 className={`${styles.titleNumber} md:mr-6 mr-3`}>01</h2>

                    <h2
                        className={`${styles.title} md:text-[26px] lg:text-[32px] font-bold text-lg w-60 md:w-auto`}
                    >
                        ハイクラスな人材のみが受け取れる
                    </h2>
                </div>
                <p className="md:text-lg text-sm md:ml-36 ml-4 md:mt-5 mt-6 mb-10">
                    平均単価170万円/人月のハイクラスな案件
                </p>

                {isClient &&
                    caseData.map((d, id) => {
                        return (
                            <Case
                                key={id}
                                image={d.image}
                                userAge={d.userAge}
                                unitPrice={d.unitPrice}
                                introduction={d.introduction}
                                projectTitle={d.projectTitle}
                                reward={d.reward}
                                rate={d.rate}
                                jobCategory={d.jobCategory}
                                place={d.place}
                                body={d.body}
                            />
                        );
                    })}

                <div
                    className={`${styles.body} text-[20px] text-center mx-auto my-10`}
                >
                    <p>
                        書類審査をパスした方だけが、案件の紹介を受けられるスクリーニング制度
                        <br />
                        クライアントもProConnectの人材はレベルが違うと言っていただいています。
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Appeal2;
