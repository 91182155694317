import { Master } from '@/types/master';

export const startWorkingDaySelectMasters: Master[] = [
    { id: '1', text: '今月' },
    { id: '2', text: '来月' }
];

export const STARTTIME_MASTERS_IDS = {
    CURRENT_MONTH: '1',
    NEXT_MONTH: '2'
};