import React, { useMemo, useState } from 'react';
import ProjectModal from '@/components/Project/ProjectModal';
import styles from './styles.module.css';
import { formatProjectDate } from '@/modules/project';
import { ProjectProps } from '@/types';

type Props = {
    project: ProjectProps;
    onClickSignUp: (id: String) => void;
    onClickSignIn: (id: String) => void;
};

const ProjectCard: React.FC<Props> = ({
    project,
    onClickSignUp,
    onClickSignIn
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const projectSpan = useMemo(
        () =>
            formatProjectDate(
                project.taskStartDate.toString(),
                project.taskEndDate.toString(),
                'ー',
                ' 〜 '
            ),
        [project.taskStartDate, project.taskEndDate]
    );

    return (
        <>
            <ProjectModal
                project={project}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onClickSignUp={() => onClickSignUp(project.id)}
                onClickSignIn={() => onClickSignIn(project.id)}
            />
            <div
                className="h-auto w-auto cursor-pointer"
                onClick={() => setIsModalOpen(true)}
            >
                <div
                    className={`${styles.cardTitle} w-[320px] h-[114px] mb-0 flex items-center`}
                >
                    <span
                        className={`${styles.titleText} text-lg w-auto text-left table-cell align-middle`}
                    >
                        {project.title}
                    </span>
                </div>
                <div
                    className={`${styles.cardMain} w-auto h-40 box-border px-5`}
                >
                    <div className="flex items-center">
                        <p
                            className={`${styles.item} w-[92px] mt-4 mr-4 text-xs text-center rounded`}
                        >
                            単価
                        </p>
                        <p className="mt-4 text-sm font-medium">
                            〜{' '}
                            <span className="text-2xl">{project.maxFee}</span>{' '}
                            万円/人月
                        </p>
                    </div>
                    <div className="flex items-center">
                        <p
                            className={`${styles.item} w-[92px] mt-4 mr-4 text-xs text-center rounded`}
                        >
                            稼働率
                        </p>
                        <p className="mt-4 text-sm font-medium">
                            {project.operationMin ? (
                                <span>
                                    {project.operationMin} 〜{' '}
                                    {project.operationMax}
                                </span>
                            ) : (
                                <span>{project.operationMax}</span>
                            )}
                        </p>
                    </div>
                    <div className="flex items-center mt-1">
                        <p
                            className={`${styles.item} w-[92px] mt-4 mr-4 text-xs text-center rounded`}
                        >
                            案件期間
                        </p>
                        <p className="mt-4 text-xs font-medium">
                            {projectSpan}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectCard;
