import Link from 'next/link';
import styles from './styles.module.css';
import ColumnCard from './ColumnCard/index';
import { ColumnDataProps } from '../../types';
import { useUrlContext } from '../../provider/urlContext';

const Column = ({
    columnContentList
}: {
    columnContentList: ColumnDataProps[];
}) => {
    const { query } = useUrlContext();
    // 0件の時は表示しない
    if (columnContentList.length == 0) return null;
    return (
        <div className="md:py-30 py-10 w-full lg:min-w-[1000px]" id="columns">
            <h2 className="font-noto-serif font-bold text-lg md:text-4xl text-center">
                コラム
            </h2>
            <div className="bg-primary mx-auto mt-3 mb-10 md:mb-20 h-[2px] md:h-1 md:w-24 w-10"></div>
            <div className="border-t-2 border-[#cfcfcf] max-w-[750px] mx-4 md:mx-auto">
                {columnContentList.map((column, id) => (
                    <ColumnCard
                        key={id}
                        title={column.title}
                        body={column.content}
                        date={column.publishedAt}
                        image={column.imageUrl}
                        link={`/columns/detail/${column.slug ?? column.id}`}
                        categoriesAsTag={column.categoriesAsTag}
                    />
                ))}
                <div className="flex justify-end max-w-[1000px] md:mx-auto mt-6">
                    <Link href={`/columns${query}`} passHref>
                        <div className="text-primary text-right text-base md:text-lg font-bold">
                            <p className="relative pr-8 cursor-pointer">
                                もっと見る
                                <span className={styles.key}></span>
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Column;
