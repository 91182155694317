import React from 'react';
import styles from './styles.module.css';

export type Props = {
    stepNumber: string;
    image: string;
    children: React.ReactNode;
};

const Index: React.VFC<Props> = ({ children, stepNumber, image }) => (
    <div
        className="py-0 px-4 m-0 w-[220px] h-[218px] bg-[#F7F7F7] box-border rounded justify-center items-center flex relative flex-col border-primary border-solid border-2 top-0 left-0"
        id={styles.root}
    >
        <div
            className="py-0 px-4 mx-1 w-[120px] h-[44px] justify-center items-center flex bg-[#F7F7F7] top-0 left-0 absolute"
            id={styles.title}
        >
            <p className="not-italic font-normal text-[22px] leading-[30px] m-0 pb-0">
                STEP
            </p>
            <p
                className="not-italic font-normal text-[32px] leading-[30px] m-0 ml-[3px] pb-0"
                id={styles.number}
            >
                {stepNumber}
            </p>
        </div>
        <div className="my-[15px] mx-auto">
            <img src={image} alt="icon" />
        </div>
        <div
            className="h-[60px] not-italic font-medium leading-[22px] mt-0 mx-auto mb-[15px] whitespace-pre-wrap"
            id={styles.content}
        >
            {children}
        </div>
    </div>
);

export default Index;
