import { recordOnClickSignUp } from '../../../modules/log';
import { useUrlContext } from '../../../provider/urlContext';

const RightArrowForPC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
    >
        <path
            d="M12.0273 26L23.3411 14.6863L12.0273 3.37258"
            stroke="#1D55AE"
            strokeWidth="3"
            strokeLinecap="round"
        />
    </svg>
);

const RightArrowForSP = () => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.29688 19.0664L16.5936 10.7697L8.29688 2.47297"
            stroke="#1D55AE"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const SignUpButton = () => {
    const domain = process.env.NEXT_PUBLIC_PRO_CONNECT_APP_FL_DOMAIN;
    const { signUpUrl } = useUrlContext();
    const signUpUrlWithDomain = `https://${domain}${signUpUrl}`;
    return (
        <div className="w-full h-[76px] md:h-[105px] px-4 bg-white fixed bottom-0 z-[200] flex items-center">
            <a
                href={signUpUrlWithDomain}
                onClick={() => recordOnClickSignUp()}
                className="bg-[#FFEC5D] md:max-w-[750px] w-full h-[60px] md:h-[73px] flex justify-center items-center mx-auto rounded-[60px] hover:opacity-70 cursor-pointer px-[16px]"
            >
                <span className="md:px-[10px] px-[8px] py-1 md:mr-3 mr-2 md:ml-6 text-sm md:text-2xl text-[#FFEC5D] bg-[#1D55AE] rounded">
                    簡単1分
                </span>
                <span className="text-[#1D55AE] text-[19px] md:text-3xl not-italic font-bold leading-10 relative top-[2px]">
                    無料会員登録はこちら
                </span>
                <div className='hidden md:block'>
                    <RightArrowForPC />
                </div>
                <div className='block md:hidden'>
                    <RightArrowForSP />
                </div>
            </a>
        </div>

    );
};

export default SignUpButton;
