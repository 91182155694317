import dayjs from 'dayjs';
import microCMSApi, { defaultQuery } from '../modules/config/microCMSApi';
import {
    ColumnCategoryAsTagData,
    ColumnCategoryAsTagDataProps,
    ColumnContentOgp,
    ColumnData,
    ColumnDataProps,
    SearchHistory
} from '../types';
import { omittedDescription } from './tool';
import firestoreApi from './api/firestore';
import api, { columnOgpPrefixPath } from './api/axios';

const CASE_STUDY_ID = 'gcjamzjoe';

const SEARCH_HISTORY_COLLECTION = 'search_histories_v1';

export const ITEM_COUNT_PER_PAGE = 7;

export const convertDataToProps = (
    columnData: ColumnData
): ColumnDataProps => ({
    id: columnData.id,
    title: columnData.title || '',
    content: columnData.content || '',
    summary: columnData.summary || '',
    imageUrl:
        columnData.eyecatch?.url ??
        'https://images.microcms-assets.io/assets/894eb1f1cbd34089b5d806042fd2e730/44b89382fcb24c2ea9620a4739507def/Facebook.png',
    category: columnData.category ? columnData.category.name : null,
    publishedAt: dayjs(columnData.publishedAt).format('YYYY.MM.DD') || '',
    slug: columnData.slug || null,
    categoriesAsTag: columnData.tags.map((tag) => tag.name)
});

export const convertCategoryAsTagDataToProps = (
    categoryAsTagData: ColumnCategoryAsTagData,
    totalCount: number
): ColumnCategoryAsTagDataProps => ({
    id: categoryAsTagData.id,
    name: categoryAsTagData.name,
    totalCount
});

export const loadColumnsFromMicroCmsForLP = async () => {
    const filters = `category[not_equals]${CASE_STUDY_ID}`;

    const { contents } = await microCMSApi.get<ColumnData>('columns', {
        ...defaultQuery,
        filters,
        orders: '-publishedAt',
        limit: 4
    });
    const choiceColumnData = contents.map((d) => convertDataToProps(d));
    return choiceColumnData;
};

/** 全てのコラムもしくはケーススタディを取得 */
export const loadColumns = async (
    offset?: number,
    limit?: number,
    text?: string
) => {
    let filters = `category[not_equals]${CASE_STUDY_ID}`;

    if (text) {
        filters += `[and](title[contains]${text}[or]content[contains]${text})`;
        const searchHistoryData: SearchHistory['data'] = {
            word: text,
            createdDt: new Date()
        };
        // 非同期的に実行
        firestoreApi.add<SearchHistory>(
            SEARCH_HISTORY_COLLECTION,
            searchHistoryData
        );
    }

    const { contents, totalCount } = await microCMSApi.get<ColumnData>(
        'columns',
        {
            ...defaultQuery,
            orders: '-publishedAt',
            offset: offset || defaultQuery.offset,
            limit: limit || defaultQuery.limit,
            filters
        }
    );
    return {
        columns: contents.map((d) => convertDataToProps(d)),
        totalCount
    };
};

/** カテゴリ(タグ)でコラムを取得する */
export const loadColumnsByCategoryAsTag = async (
    category: string,
    offset?: number,
    limit?: number
): Promise<{ columns: ColumnDataProps[]; totalCount: number }> => {
    // idを取得する
    const { contents: categoryContent } =
        await microCMSApi.get<ColumnCategoryAsTagData>('column-categories', {
            ...defaultQuery,
            filters: `name[equals]${category}`
        });
    if (categoryContent.length === 0) return { columns: [], totalCount: 0 };
    const categoryId = categoryContent[0].id;

    // カテゴリidを使用してコラムを取得する
    const { contents, totalCount } = await microCMSApi.get<ColumnData>(
        'columns',
        {
            ...defaultQuery,
            orders: '-publishedAt',
            offset: offset || defaultQuery.offset,
            limit: limit || defaultQuery.limit,
            filters: `category[not_equals]${CASE_STUDY_ID}[and]tags[contains]${categoryId}`
        }
    );

    return {
        columns: contents.map((d) => convertDataToProps(d)),
        totalCount
    };
};

/** 個別のコラムもしくはケーススタディを取得 */
export const loadColumn = async (idOrSlug: string) => {
    // まずはslugで検索する
    const { contents: slugContents } = await microCMSApi.get<ColumnData>(
        'columns',
        {
            ...defaultQuery,
            filters: `slug[equals]${idOrSlug}`
        }
    );
    if (slugContents.length > 0) {
        return convertDataToProps(slugContents[0]);
    }
    // slugで見つからなかった場合はidで検索する
    const { contents: idContents } = await microCMSApi.get<ColumnData>(
        'columns',
        {
            ...defaultQuery,
            ids: idOrSlug
        }
    );
    if (idContents.length !== 0) {
        return convertDataToProps(idContents[0]);
    }

    return null;
};

export const loadColumnCategoriesAsTag = async () => {
    const { contents } = await microCMSApi.get<ColumnCategoryAsTagData>(
        'column-categories',
        {
            ...defaultQuery
        }
    );
    const categoriesAsTagWithTotalCount = await Promise.all(
        contents.map(async (category) => {
            const { totalCount } =
                await microCMSApi.get<ColumnCategoryAsTagData>('columns', {
                    ...defaultQuery,
                    limit: 0,
                    filters: `category[not_equals]${CASE_STUDY_ID}[and]tags[contains]${category.id}`
                });
            return { content: category, totalCount };
        })
    );
    return {
        categoriesAsTag: categoriesAsTagWithTotalCount.map(
            ({ content, totalCount }) =>
                convertCategoryAsTagDataToProps(content, totalCount)
        )
    };
};

export const omittedHtmlTagFromText = (text: string): string => {
    const omittedText = text.replace(/<(.*?)>|&(.*?);/g, '');
    return omittedDescription(omittedText, 275);
};

/** コンテンツにbrタグが設定されている場合は削除する */
const removeBrTag = (content: any) => {
    if (Array.isArray(content.props.children)) {
        return content.props.children
            .filter(
                (content: any) =>
                    typeof content === 'string' ||
                    (content.type && content.type !== 'br')
            )
            .join(' ');
    }
    return content.props.children;
};

/**
 * 何層目のリストなのかを調べる
 * @param element 要素
 * @returns 何層目のリストか
 */
export const countParentOl = (element: any): number => {
    let count = 0;
    if (!element) return count;
    if (element.name === 'ol') count++;
    if (element.parent) count += countParentOl(element.parent);
    return count;
};

/** 目次作成 */
export const createSummary = (parsed: string | JSX.Element | JSX.Element[]) => {
    let summaries: { id: string; text: string; headingNumber: number }[] = [];
    if (typeof parsed === 'string') {
        summaries = [];
    } else if (!Array.isArray(parsed)) {
        const type = parsed.type as string;
        if (!type || (type && !type.match(/^h\d$/))) return [];
        const headingNumber = parseInt(
            type.substring(type.indexOf('h')).substring(1)
        );
        if (headingNumber <= 3) {
            const text = removeBrTag(parsed);
            return [
                {
                    id: parsed.props.id,
                    text: text,
                    headingNumber
                }
            ];
        }
        return [];
    } else {
        summaries = parsed
            .filter((content) => {
                const type = content.type as string;
                if (!type || (type && !type.match(/^h\d$/))) return false;
                const headingNumber = parseInt(
                    type.substring(type.indexOf('h')).substring(1)
                );
                return headingNumber <= 3;
            })
            .map((content) => {
                const type = content.type as string;
                const text = removeBrTag(content);
                return {
                    id: content.props.id,
                    text: text,
                    headingNumber: parseInt(
                        type.substring(type.indexOf('h')).substring(1)
                    )
                };
            });
    }
    return summaries;
};

/**
 * functionsを経由して、urlからogp情報を取得します。
 * @param url ogpを取得したいサイトのurl
 * @returns urlに対応したogp情報
 */
export const getOgpInfoFromUrl = async (
    url: string
): Promise<ColumnContentOgp | null> => {
    const res = await api.post<{ url: string }, ColumnContentOgp>(
        columnOgpPrefixPath,
        {
            url
        }
    );
    return res;
};
