import React from 'react';
import Step from './Steps';
import styles from './styles.module.css';
import step1 from '../../public/images/step1.png';
import step2 from '../../public/images/step2.png';
import step3 from '../../public/images/step3.png';
import step4 from '../../public/images/step4.png';

const subscriptRefText = `会員登録からご参画まで
最短2日〜でご案内可能です。`;
const step2RefText = `面談にてご希望の条件や
案件をヒアリング`;
const step3RefText = `ご希望に合った案件を紹
介/検索して応募`;
const step4RefText = `クライアントとの面談を経てオファー、契約`;

const process: React.FC = () => {
    return (
        <section id="process" className="w-full lg:min-w-[1000px]">
            <div className="w-full py-[40px] md:py-[120px]">
                <div className="w-full justify-center items-center flex flex-col mx-auto lg:w-[1000px]">
                    <div className="justify-center items-center flex-col mb-12">
                        <h2
                            className="text-[18px] leading-[26px] tracking-normal text-center font-bold md:text-[36px] md:leading-[52px]"
                            id={styles.captionTitle}
                        >
                            案件参画までのプロセス
                        </h2>
                        <div className="w-[40px] h-1 mt-3 bg-primary mx-auto md:w-[90px]" />
                        <div className="text-[14px] leading-[19px] not-italic font-medium text-center whitespace-pre-wrap mt-[24px] lg:mt-10 lg:w-[449px] md:h-[25px] md:text-[18px] md:leading-[25px] md:whitespace-nowrap">
                            <p>{subscriptRefText}</p>
                        </div>
                    </div>
                    <div className="mx-auto lg:items-center lg:justify-center lg:flex">
                        <Step stepNumber="01" image={step1.src}>
                            <p>書類審査</p>
                        </Step>
                        <div
                            className="w-0 mt-2 mb-4 mx-auto align-middle border-l-[23px] border-l-solid rotate-90 lg:rotate-0 lg:mx-2"
                            id={styles.triangle}
                        />
                        <Step stepNumber="02" image={step2.src}>
                            <p>{step2RefText}</p>
                        </Step>
                        <div
                            className="w-0 mt-2 mb-4 mx-auto align-middle border-l-[23px] border-l-solid rotate-90 lg:rotate-0 lg:mx-2"
                            id={styles.triangle}
                        />
                        <Step stepNumber="03" image={step3.src}>
                            <p>{step3RefText}</p>
                        </Step>
                        <div
                            className="w-0 mt-2 mb-4 mx-auto align-middle border-l-[23px] border-l-solid rotate-90 lg:rotate-0 lg:mx-2"
                            id={styles.triangle}
                        />
                        <Step stepNumber="04" image={step4.src}>
                            <p>{step4RefText}</p>
                        </Step>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default process;
