import { FirestoreDate } from '@/types';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import 'dayjs/locale/ja'; // 日本語ロケールを設定
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const now = () => dayjs().tz('Asia/Tokyo');

export const toDate = (date: FirestoreDate): Date => {
    const isFirestoreDate = date instanceof Timestamp;
    if (isFirestoreDate) {
        return (date as Timestamp).toDate();
    }
    if (
        (date as any)?._nanoseconds !== undefined && // eslint-disable-line
        (date as any)?._seconds !== undefined // eslint-disable-line
    ) {
        const milliseconds =
            ((date as any)._nanoseconds || 0) / 1000000 + // eslint-disable-line
            ((date as any)._seconds || 0) * 1000; // eslint-disable-line
        return new Date(milliseconds);
    }
    return date as Date;
};

export const formatDate = (date: Date | string): string =>
    dayjs(date).tz('Asia/Tokyo').format('YYYY/MM/DD');

export const parseDate = (dateString: string): FirestoreDate => {
    if (!dateString) return null;

    const parsedDate = dayjs(dateString, 'YYYY/MM/DD');
    if (!parsedDate || !parsedDate.isValid()) return null;

    const utcDate = new Date(
        parsedDate.valueOf() - parsedDate.utcOffset() * 60 * 1000
    );
    return Timestamp.fromDate(utcDate);
};

export const changeDateToComparableNumber = (
    date: FirestoreDate,
    defaultValue: number = Number.NEGATIVE_INFINITY
): number => {
    if (!date) return defaultValue;
    const d = toDate(date);
    return d.getTime();
};

export const compareDate = (
    a: FirestoreDate,
    b: FirestoreDate,
    order: 'ASC' | 'DESC'
) => {
    const aa = changeDateToComparableNumber(a);
    const bb = changeDateToComparableNumber(b);
    if (order == 'ASC') return aa - bb;
    else return bb - aa;
};

export function isPast(firestoreDate: FirestoreDate): boolean {
    const date = toDate(firestoreDate);
    if (!date) return false;
    const now = new Date();
    return date.getTime() < now.getTime();
}

export const getCurrentMonthFirstDate = (): Date => {
    const currentDate = dayjs().tz('Asia/Tokyo');
    return currentDate.startOf('month').toDate();
};

export const getCurrentMonthLastDate = (): Date => {
    const currentDate = dayjs().tz('Asia/Tokyo');
    return currentDate.endOf('month').toDate();
};

export const getCurrentMonthFirstAndLastDate = () => {
    return {
        currentMonthFirstDate: getCurrentMonthFirstDate().toISOString(),
        currentMonthEndDate: getCurrentMonthLastDate().toISOString()
    };
};

export const getNextMonthFirstAndLastDate = () => {
    const nextMonth = now().add(1, 'month');
    return {
        nextMonthFirstDate: nextMonth.startOf('month').toISOString(),
        nextMonthEndDate: nextMonth.endOf('month').toISOString()
    };
};