import React from 'react';
import Image from 'next/image';
import SignUp from '@/components/SignUp';

const imagePCBackgroundFv = '/images/short-lp/pc/lp-top-background.webp';
const imagePCHumanFv = '/images/short-lp/pc/lp-top-human.webp';
const imageSPBackgroundFv = '/images/short-lp/sp/lp-top-background-sp.webp';
const imageSPHumanFv = '/images/short-lp/sp/lp-top-human-sp.webp';

const FirstView = () => {
    return (
        <div className="flex flex-col md:flex-row h-full">
            <div className="relative w-full">
                <Image
                    src={imagePCBackgroundFv}
                    alt="FV背景"
                    fill
                    className="md:block hidden object-[right_calc(20%)_top_calc(0%)] z-[-10] object-cover"
                />
                <Image
                    src={imagePCHumanFv}
                    alt="FV人物"
                    fill
                    className="md:block hidden object-[right_calc(20%)_top_calc(0%)] z-[-1] object-cover md:max-w-[440px] lg:max-w-[550px] md:ml-auto lg:mr-20"
                />
                <Image
                    src={imageSPBackgroundFv}
                    alt="FV背景"
                    fill
                    className="md:hidden block object-[right_calc(20%)_top_calc(0%)] z-[-10] object-cover"
                />
                <Image
                    src={imageSPHumanFv}
                    alt="FV人物"
                    fill
                    className="md:hidden block object-[right_calc(20%)_top_calc(0%)] z-[-1] object-cover md:max-w-[440px] lg:max-w-[550px] md:ml-auto"
                />
                <div className="py-[20px] md:pb-[60px] md:pt-[100px] px-[12px] lg:px-[70px] xl:px-[120px] mt-62 md:mt-0">
                    <p className="font-hiragino-sans text-white text-[28px] sm:text-[38px] lg:text-[44px] xl:text-[60px] leading-[60px] sm:leading-[70px] md:leading-[80px] xl:leading-[90px] md:mb-[40px] mx-[16px]">
                        <span className="md:block">
                            フリーランス
                            <br />
                            コンサルタントのための
                            <br />
                            案件紹介サービス
                        </span>
                    </p>
                </div>
                <SignUp />
            </div>
        </div>
    );
};

export default FirstView;
