import React from 'react';
import styles from './styles.module.css';

export type Props = {
    children: React.ReactNode;
    title: string;
};

const Index: React.VFC<Props> = ({ children, title }) => (
    <div
        className="w-[343px] h-[271px] rounded-sm flex justify-center items-center border-solid border-[3px] md:w-[480px] md:h-[284px]"
        id={styles.outerCard}
    >
        <div className="w-[327px] h-[255px] rounded flex justify-center items-center flex-col bg-white md:w-[464px] md:h-[268px]">
            <div className="flex items-center" id={styles.line}>
                <h3
                    className="w-[130px] h-[23px] not-italic font-bold text-[16px] leading-[23px] text-center mx-0 md:text-[22px] md:leading-8 md:mt-8 md:mb-6 md:w-[185px]"
                    id={styles.title}
                >
                    {title}
                </h3>
            </div>
            <p className="w-[297px] h-[160px] mb-[32px] not-italic font-medium text-[14px] tracking-[0.01em] whitespace-pre-wrap mx-auto md:mb-10 md:h-32 md:w-[416px]">
                {children}
            </p>
        </div>
    </div>
);

export default Index;
