import React, { useState } from 'react';
import { Case } from '../ActualResults/caseData';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion';

import styles from './styles.module.css';

type Col = {
    name: string;
    value: string;
};

const PjBox = (props: Col) => {
    return (
        <div className="flex text-xs mt-2 mb-3">
            <p
                className={`${styles.projectName} w-[75px] mr-2 py-1 px-3 text-center md:rounded rounded-3xl border border-primary`}
            >
                {props.name}
            </p>
            <p className={`${styles.projectValue} py-1 text-sm`}>
                {props.value}
            </p>
        </div>
    );
};

type Props = {
    text: string;
};

const AccordionData: React.VFC<Props> = ({ text }) => {
    return (
        <div className="h-auto md:w-full w-[280px]">
            <p className="font-bold mb-2">内容</p>
            <p className="text-sm">
                <span className={styles.text}>{text}</span>
            </p>
        </div>
    );
};

const Index = (props: Case) => {
    const [isOpened, setIsOpened] = useState(false);

    const toggleAccordion = () => {
        setIsOpened(!isOpened);
    };

    return (
        <div
            className={`${styles.container} bg-light h-auto md:mx-4 lg:mx-10 flex mx-auto md:mt-10 mt-6 rounded-tr rounded-b`}
        >
            {/* user情報 */}
            <div className="md:max-w-[304px] lg:w-[304px] w-auto h-auto bg-[#DAE2EA] rounded-tl md:rounded-bl rounded-tr md:rounded-tr-none">
                <img
                    src={props.image}
                    alt="人材イメージ"
                    loading="lazy"
                    className="mx-auto mt-8 mb-2"
                />
                <div className="text-center mb-6">
                    <p>{props.userAge}代男性</p>
                    <p>設定単価{props.unitPrice}万〜/人月</p>
                </div>
                <div className="mx-auto px-2 w-[240px] mb-6">
                    <p className="text-xs">{props.introduction}</p>
                </div>
            </div>

            {/* 案件情報 */}
            <div className="h-auto w-full mx-auto mx:pl-8 pl-4 mx:py-8 py-6">
                <p className="font-bold">成約案件</p>
                <PjBox name="案件事例" value={props.projectTitle} />
                <PjBox name="報酬" value={`${props.reward}万円`} />
                <PjBox name="稼働率" value={`${props.rate}%`} />
                <PjBox name="領域" value={props.jobCategory} />
                <PjBox name="勤務地" value={props.place} />

                <div className="mx-auto">
                    <Accordion
                        allowZeroExpanded
                        onChange={() => toggleAccordion()}
                    >
                        <AccordionItem>
                            <AccordionItemHeading>
                                {isOpened ? (
                                    <AccordionItemButton
                                        className={`${styles.button} w-[300px] mx-auto mt-6  mb-4 border rounded text-center px-[84px] py-2 relative`}
                                    >
                                        内容をもっと見る
                                        <div className={styles.minus}></div>
                                    </AccordionItemButton>
                                ) : (
                                    <AccordionItemButton
                                        className={`${styles.button} w-[300px] mx-auto mt-6  md:mb-4 mb-0 border rounded text-center px-[84px] py-2 relative bg-primary text-white`}
                                    >
                                        内容をもっと見る
                                        <div className={styles.plus}></div>
                                    </AccordionItemButton>
                                )}
                            </AccordionItemHeading>
                            <AccordionItemPanel
                                className={styles.accordionPanel}
                            >
                                <AccordionData text={props.body} />
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default Index;
