import React, { ReactNode, useState } from 'react';
import styles from './styles.module.css';

export type Props = {
    question: ReactNode;
    answer: ReactNode;
};

const QandA: React.FC<Props> = ({ question, answer }) => {
    const [isToggleOn, setIsToggleOn] = useState(false);
    if (!isToggleOn) {
        return (
            <button
                className="w-full border-y border-[#E5E5E5] lg:w-[920px]"
                onClick={() => setIsToggleOn(true)}
            >
                <div className="flex py-[12px] min-h-[56px] items-center md:min-h-[80px] md:py-[29px] w-full px-[12px] md:px-[32px]">
                    <div className="w-[32px] h-[32px] md:w-[44px] md:h-[44px]">
                        <div className="w-[32px] h-[32px] md:w-[44px] md:h-[44px] bg-primary rounded-[16px] md:rounded-[22px] text-white text-[16px] md:text-[20px] leading-8 md:leading-[44px]">
                            Q
                        </div>
                    </div>
                    <p className="text-[14px] leading-[19px] ml-[24px] font-medium text-left whitespace-pre-wrap md:text-[16px] md:leading-[22px] w-full">
                        {question}
                    </p>
                    <div className="ml-auto w-[28px] md:w-[60px] pl-[12px] md:pl-[40px]">
                        <span
                            className="align-middle leading-none relative rounded-[1.6px] block"
                            id={styles.dliPlus}
                        ></span>
                    </div>
                </div>
            </button>
        );
    }
    return (
        <div className="flex flex-col w-full">
            <button
                className="w-full border-y border-[#E5E5E5] lg:mx-auto lg:w-[920px]"
                onClick={() => setIsToggleOn(false)}
            >
                <div className="flex py-[12px] min-h-[56px] items-center md:min-h-[80px] md:py-[29px] w-full px-[12px] md:px-[32px]">
                    <div className="w-[32px] h-[32px] md:w-[44px] md:h-[44px]">
                        <div className="w-[32px] h-[32px] md:w-[44px] md:h-[44px] bg-primary rounded-[16px] md:rounded-[22px] text-white text-[16px] md:text-[20px] leading-8 md:leading-[44px]">
                            Q
                        </div>
                    </div>
                    <p className="text-[14px] leading-[19px] ml-[24px] font-medium text-left whitespace-pre-wrap md:text-[16px] md:leading-[22px] w-full">
                        {question}
                    </p>
                    <div className="ml-auto w-[28px] md:w-[60px] pl-[12px] md:pl-[40px]">
                        <span
                            className="align-middle leading-none relative block"
                            id={styles.dliMinus}
                        ></span>
                    </div>
                </div>
            </button>
            <div className="flex pl-[12px] pr-[12px] w-full min-h-[56px] bg-[#F7F7F7] items-start lg:mx-auto lg:w-[920px] md:min-h-[80px] md:pl-[32px]">
                <div className="mt-[16px] w-[32px] h-[32px] border rounded-[16px] flex justify-center items-center md:w-[44px] md:h-[44px] md:rounded-[22px] bg-white shrink-0">
                    <p className="text-[16px] md:text-[20px]">A</p>
                </div>
                <p className="ml-[24px] text-[14px] leading-[19px] font-medium my-[16px] whitespace-pre-wrap md:text-[16px] md:leading-[22px] md:my-[29px] w-full">
                    {answer}
                </p>
            </div>
        </div>
    );
};

export default QandA;
