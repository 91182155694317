import Link from 'next/link';
import { useUrlContext } from '../../provider/urlContext';
import { recordOnClickSignUp } from '../../modules/log';

const Google = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M22.56 12.25C22.56 11.47 22.49 10.72 22.36 10H12V14.26H17.92C17.66 15.63 16.88 16.79 15.71 17.57V20.34H19.28C21.36 18.42 22.56 15.6 22.56 12.25Z"
            fill="#4285F4"
        />
        <path
            d="M11.9997 23C14.9697 23 17.4597 22.02 19.2797 20.34L15.7097 17.57C14.7297 18.23 13.4797 18.63 11.9997 18.63C9.13969 18.63 6.70969 16.7 5.83969 14.1H2.17969V16.94C3.98969 20.53 7.69969 23 11.9997 23Z"
            fill="#34A853"
        />
        <path
            d="M5.84 14.09C5.62 13.43 5.49 12.73 5.49 12C5.49 11.27 5.62 10.57 5.84 9.91001V7.07001H2.18C1.43 8.55001 1 10.22 1 12C1 13.78 1.43 15.45 2.18 16.93L5.03 14.71L5.84 14.09Z"
            fill="#FBBC05"
        />
        <path
            d="M11.9997 5.38C13.6197 5.38 15.0597 5.94 16.2097 7.02L19.3597 3.87C17.4497 2.09 14.9697 1 11.9997 1C7.69969 1 3.98969 3.47 2.17969 7.07L5.83969 9.91C6.70969 7.31 9.13969 5.38 11.9997 5.38Z"
            fill="#EA4335"
        />
    </svg>
);

const Apple = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
    >
        <g clipPath="url(#clip0_171_71977)">
            <path
                d="M18.9333 8.1816C18.794 8.2896 16.3339 9.6744 16.3339 12.7536C16.3339 16.3152 19.4643 17.5752 19.558 17.6064C19.5435 17.6832 19.0607 19.332 17.9075 21.012C16.8793 22.4904 15.8054 23.9664 14.1718 23.9664C12.5381 23.9664 12.1177 23.0184 10.2318 23.0184C8.39399 23.0184 7.74054 23.9976 6.24625 23.9976C4.75195 23.9976 3.70931 22.6296 2.51051 20.9496C1.12192 18.9768 0 15.912 0 13.0032C0 8.3376 3.03664 5.8632 6.02523 5.8632C7.61321 5.8632 8.93694 6.9048 9.93393 6.9048C10.8829 6.9048 12.3628 5.8008 14.1694 5.8008C14.8541 5.8008 17.3141 5.8632 18.9333 8.1816ZM13.3117 3.8256C14.0589 2.94 14.5874 1.7112 14.5874 0.4824C14.5874 0.312 14.573 0.1392 14.5417 0C13.3261 0.0456 11.8799 0.8088 11.0078 1.8192C10.3231 2.5968 9.68408 3.8256 9.68408 5.0712C9.68408 5.2584 9.71532 5.4456 9.72973 5.5056C9.80661 5.52 9.93153 5.5368 10.0565 5.5368C11.1471 5.5368 12.5189 4.8072 13.3117 3.8256Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_171_71977">
                <rect width="19.5556" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const SignUp = () => {
    const domain = process.env.NEXT_PUBLIC_PRO_CONNECT_APP_FL_DOMAIN;
    const { signUpUrl } = useUrlContext();
    const signUpUrlWithDomain = `https://${domain}${signUpUrl}`;

    return (
        <div className="flex items-center md:justify-between md:mx-4 lg:mx-0 lg:justify-center flex-col md:flex-row px-[32px] md:px-0 mb-5">
            <Link
                href={signUpUrlWithDomain}
                passHref
                className="rounded-[60px] bg-[#ffec5d] hover:opacity-70 w-full md:w-[526px] h-[48px] md:h-16 font-noto-sans flex items-center justify-center mr-0 md:mr-[20px] mb-[14px] md:mb-0"
                onClick={() => recordOnClickSignUp()}
                style={{ fontFamily: 'sans-serif' }}
            >
                <span className="text-[13px] md:text-[18px] h-[24px] md:h-[28px] leading-[24px] md:leading-[28px] rounded-[4px] font-semibold px-[8px] text-[#ffec5d] bg-[#1D55AE] mr-[2px]">
                    簡単1分
                </span>
                <span className="text-[#1D55AE] text-[14px] md:text-xl inline-block not-italic font-bold leading-[100%]">
                    で無料会員登録する
                </span>
            </Link>
            <div className="flex items-center">
                <p className="text-[#FFF] text-xs not-italic font-light leading-[normal] mr-[16px]">
                    または次の方法で会員登録する
                </p>
                <Link
                    href={signUpUrlWithDomain}
                    passHref
                    className="w-[42px] h-[42px] hover:opacity-70 flex justify-center items-center bg-white rounded-[50%] mr-[20px] cursor-pointer"
                >
                    <Google />
                </Link>
                <Link
                    href={signUpUrlWithDomain}
                    passHref
                    className="w-[42px] h-[42px] hover:opacity-70 flex justify-center items-center bg-white rounded-[50%] cursor-pointer"
                >
                    <Apple />
                </Link>
            </div>
        </div>
    );
};

export default SignUp;
