export type Case = {
    image: string;
    userAge: number;
    unitPrice: number;
    introduction: string;
    projectTitle: string;
    reward: number;
    rate: number;
    jobCategory: string;
    place: string;
    body: string;
};

export const caseData: Case[] = [
    {
        image: '/images/case1.png',
        userAge: 40,
        unitPrice: 180,
        introduction:
            '登録から5営業日でアサイン\n経営戦略、新規事業の立ち上げなど戦略周りに関してご経験豊富。',
        projectTitle: 'ロボット関連DXコンサル立ち上げ/JV設立に伴う業務支援',
        reward: 160,
        rate: 80,
        jobCategory: '戦略コンサル',
        place: '東京23区内 リモート＋出社',
        body: `業務別DXコンサルタント\n・DXコンサルティング事業を開始するためのサービス設計\n・DXコンサルティングの実業務＆サービスフラッシュアップ(小売業、オフィス系)\n\nJV設立支援\n・JV設立支援に向けた両社の方針/業務分担設計\n・設立に向けた各種業務設計PMO`
    },
    {
        image: '/images/case2.png',
        userAge: 20,
        unitPrice: 160,
        introduction:
            '4年ほど大手コンサルファームで物流改善PJやSAP導入PJへ多数ご参画経験あり。',
        projectTitle: 'グループ一体的なサプライチェーンのオペレーション改革',
        reward: 180,
        rate: 100,
        jobCategory: '業務コンサル',
        place: '東京23区内 フルリモート',
        body: '【グループ一体的なサプライチェーンのオペレーション改革】を進めるための準備Phaseとして現状業務の可視化、サプライチェーンマネジメント上のあるべき組織体制の絵姿の設計、準備フェーズ後の実行フェーズのロードマップ作成を依頼したい'
    },
    {
        image: '/images/case3.png',
        userAge: 30,
        unitPrice: 140,
        introduction:
            '国内コンサルファームで業務改善プロジェクトをメインに新規事業の立ち上げを多数ご経験。',
        projectTitle: '大手小売HDの改革プロジェクトのPMO',
        reward: 140,
        rate: 80,
        jobCategory: '業務コンサル',
        place: '東京23区内 リモート＋出社',
        body: `・改革推進時の論点整理、ロードマップの策定\n・施策の効果資産、(実行後の)結果検証\n・各事業会社とのコミュニケーション\n・役員報告、討議資料の作成`
    }
];
