import { useEffect, useState } from 'react';

export const useScrollForSignUpButton = () => {
    const [isHiddenSignUpButton, setIsHiddenSignUpButton] = useState(true);
    const showSignUpButtonPoint = 735;

    useEffect(() => {
        const checkScrollHeight = () => {
            if (document.documentElement.scrollTop < showSignUpButtonPoint) {
                setIsHiddenSignUpButton(true);
            } else {
                setIsHiddenSignUpButton(false);
            }
        };

        // スクロールイベントを監視
        window.addEventListener('scroll', checkScrollHeight);

        return () => {
            window.removeEventListener('scroll', checkScrollHeight);
        };
    }, []);

    return isHiddenSignUpButton;
};
