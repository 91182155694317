import React, { useMemo } from 'react';
import ReactModal from 'react-modal';
import { useMedia } from '../../../hooks/useMedia';
import { ProjectProps } from '../../../types';
import styles from './styles.module.css';
import { formatProjectDate } from '../../../modules/project';

const ItemName: React.VFC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <p className="inline-block rounded-[2px] border border-[#182744] text-[#182744] w-[72px] md:w-[130px] text-center text-[9px] md:text-[14px] mr-[15px] md:mr-[60px]">
            {children}
        </p>
    );
};

type Props = {
    isOpen: boolean;
    project: ProjectProps;
    onClose: () => void;
    onClickSignUp: (id: String) => void;
    onClickSignIn: (id: String) => void;
};

const ProjectModal: React.FC<Props> = ({
    isOpen,
    project,
    onClose,
    onClickSignUp,
    onClickSignIn
}) => {
    const isPC = useMedia();

    const modalStyle = React.useMemo(
        () => ({
            overlay: {
                background:
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(44.26deg, rgba(24, 39, 68, 0.72) 0.65%, rgba(1, 2, 3, 0.72) 0.66%, rgba(18, 43, 92, 0.72) 52.91%, rgba(51, 120, 254, 0.72) 100%)',
                backdropFilter: 'blur(2px)',
                zIndex: '999'
            },
            content: {
                top: '50%',
                left: '50%',
                transform: 'translateY(-50%) translateX(-50%)',
                padding: 0,
                width: `${isPC ? '631px' : '300px'}`,
                height: 'auto',
                minHeight: `${isPC ? '650px' : '321px'}`,
                maxHeight: `${isPC ? '700px' : '370px'}`,
                border: 0,
                zIndex: '999'
            }
        }),
        [isPC]
    );

    const projectSpan = useMemo(
        () =>
            formatProjectDate(
                project.taskStartDate.toString(),
                project.taskEndDate.toString(),
                'ー',
                ' 〜 '
            ),
        [project.taskStartDate, project.taskEndDate]
    );

    return (
        <ReactModal
            isOpen={isOpen}
            closeTimeoutMS={250}
            style={modalStyle}
            onRequestClose={onClose}
            ariaHideApp={false}
        >
            <div className="bg-[#182744] min-h-[86px] max-h-[150px] md:min-h-[192px] md:max-h-[250px] flex items-center justify-center">
                <p className="w-[225px] md:w-[481px] text-[#fff] text-[15px] md:text-[28px] font-[700] my-[12px]">
                    {project.title}
                </p>
            </div>
            <div className="pt-[20px] md:pt-[40px] pb-[20px] md:pb-[50px] px-[32px] md:px-[74px]">
                <div className="flex flex-col items-start">
                    <div className="flex items-center justify-center mb-[5px] md:mb-[15px]">
                        <ItemName>領域</ItemName>
                        <div className="text-[9px] md:text-[14px]">
                            {project.consultingGenre ? (
                                <p className="font-bold text-[#1D55AE]">
                                    {project.consultingGenre.text}
                                </p>
                            ) : (
                                <p>----</p>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center justify-center mb-[5px] md:mb-[15px]">
                        <ItemName>単価</ItemName>
                        <div className="text-[9px] md:text-[14px]">
                            {project.minFee ? (
                                <p className="w-[120px] md:w-[300px]">
                                    {project.minFee} 〜 {project.maxFee}
                                    万円/人月（※100%稼働時）
                                </p>
                            ) : (
                                <p>{project.maxFee}万円/人月（※100%稼働時）</p>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center justify-center mb-[5px] md:mb-[15px]">
                        <ItemName>稼働率</ItemName>
                        <p className="text-[9px] md:text-[14px]">
                            {project.operationMin}
                        </p>
                    </div>
                    <div className="flex items-center justify-center mb-[5px] md:mb-[15px]">
                        <ItemName>メイン勤務地</ItemName>
                        <p className="text-[9px] md:text-[14px]">
                            {project.mainWorkArea}
                        </p>
                    </div>
                    <div className="flex items-center justify-center mb-[5px] md:mb-[15px]">
                        <ItemName>案件期間</ItemName>
                        <p className="text-[9px] md:text-[14px]">
                            {projectSpan}
                        </p>
                    </div>
                    <div className="flex items-start justify-center">
                        <ItemName>必須スキル</ItemName>
                        <p className="text-[9px] md:text-[14px] w-40 md:w-80">
                            {project.requiredSkills !== ''
                                ? project.requiredSkills
                                : '----'}
                        </p>
                    </div>
                </div>
                <button
                    className={`${styles.signUpButton} w-full h-[26px] md:h-[42px] text-[#fff] font-[700] mt-[16px] md:mt-[36px] text-[10px] md:text-[16px]`}
                    onClick={() => onClickSignUp(project.id)}
                >
                    簡単１分登録で案件詳細を見る
                </button>
                <button
                    className={`${styles.signInButton} w-full h-[26px] md:h-[42px] text-[#fff] font-[700] mt-[12px] md:mt-[20px] text-[10px] md:text-[16px] bg-primary`}
                    onClick={() => onClickSignIn(project.id)}
                >
                    ログインして案件詳細を見る
                </button>
            </div>
        </ReactModal>
    );
};

export default ProjectModal;
